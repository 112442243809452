.contact-box {
  position: absolute;
  top: 80%;
  right: $padding-xxl;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;

  padding: 35px 45px;

  * {
    color: white;
  }

  &__icon {
    width: 30px;
    height: 30px;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;

    &.fb {
      background-image: url(../../images/icons/fb.png);
    }
    &.inst {
      background-image: url(../../images/icons/inst.png);
    }
    &.be {
      background-image: url(../../images/icons/beh.png);
      width: 35px;
    }
    &.in {
      background-image: url(../../images/icons/in.png);
    }
  }

  svg {
    height: 30px;
    width: 30px;
    opacity: .4;
    transition: all .4s;
    * {
      fill: white;
    }

    &:hover {
      opacity: 1;
    }
  }

  span {
    margin-right: 15px;
  }

  &__media {

    margin: 15px 0;
 
    a {
      margin-right: 15px;
      &:last-of-type {
        margin-right: 0;
      }
    }

    &-sm {

      svg {
        height: 21px;
        width: 21px;
      }

      span {
        margin-right: 5px;
      }

      * {
        fill: white;
        font-size: rem(14px);
      }
    }
  }

  @include mq($until: lg) {
    right: $padding-lg;
  }



  @include mq($until: sm) {
    right: 0;
    width: 100%;
    padding: 25px 20px;

    span {
      display: none;
    }
  }

  @include mq($until: xs) {
    svg {
      height: 25px;
      width: 25px;
    }
  }
}