.top {

    position: relative;
    height: calc(100vh - 120px);

    &__slider {
        top: 0;
        height: calc(100vh - 120px);
        position: absolute;
        width: 100%;
        z-index: -1;
        * {
            height: calc(100vh - 120px);
        }
    }

    &__slide {
        background-size: cover;
        background-position-y: top;
        background-position-x: center;
    }

    h1 {
        font-size: rem(40px);
    }

    h2 {
        font-size: rem(70px);
        font-weight: 600;
        line-height: 1;
        .small {
            font-size: rem(26px);
        }
    }

    &__titles {
        padding-top: 75px;
        * {
            text-align: left;
        }
    }

    margin-bottom: $v-mar-xxl;

    @include mq($until: sm) {

        &__titles {
            padding-top: 35px;
        }
        min-height: 500px;

        &__slider {
            min-height: 500px;

            * {
                min-height: 500px;
            }
        }

        h1 {
            text-align: center;
            font-size: rem(50px);
        }
        h2 {
            text-align: center;
        }
    }

    @include mq($until: xs) {
        h1 {
            font-size: 9vw;
        }
        h2 {
            font-size: 15vw;
            margin-top: 10px;
            .small {
                font-size: 4vw;
            }
        }
    }
}