.alert-container {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    top: 20px;
    z-index: 10;
    pointer-events: none;
    transition: all .4s;
    padding: 15px;
}

.alert {
    color: black!important;
    opacity: 0.9;
    &-success {
        background-color: #eaeaea;
        box-shadow: 0 0 3px black;
    }
}