.plans {
    padding-top: 20px;
    h1 {
        color: #fafafa;
        font-family: $aller-font;
        font-weight: 700;
    }

    &__list {
        display: grid;
        gap: 2vw;
        width: max-content;
        margin-left: auto;
        margin-right: auto;

        @for $i from 1 through 5 {
            &-#{$i} {
                grid-template-columns: repeat(#{$i}, 1fr);
            }
        }
    }
        
    &__vat {
        color: #8d8d8d;
        font-style: italic;
        text-align: center;
        padding: 10px 0;
    }

    &__description {
        margin-bottom: 30px;
    }

    &__name {
        font-family: $aller-font;
        font-weight: 700;
        font-size: 2.3vw;
        margin-bottom: 0;
    }

    &__popular {
        width: 100%;
        position: absolute;
        top: -2.3vw;
        content: 'popular';
        text-align: center;
        padding: 1vw 15%;
        transform-origin: center;
        left: 0;

        p {
            color: white;
            font-weight: 700;
            font-family: $aller-font;
            border-radius: 50px;
            background: linear-gradient(207.2deg, #2E4372 0%, #5D658A 100%);
            padding: .2vw .2vw .4vw;
            font-size: 1.3vw
        }
    }

    &__item {
        border: 2px solid #8d8d8d;
        border-radius: 30px;
        padding: 1.8vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: all .5s;
        position: relative;
        max-width: 300px;
        &:hover,&:nth-child(2) {
            background: linear-gradient(207.2deg, #2E4372 0%, #5D658A 100%);
            border: 2px solid black;
        }

        
        &-body {
            
            li {
                font-size: .9vw;
                line-height: 1.2;
            }
            * {
                color: #fafafa;
                text-align: left;
            }
        }

        h6 {
            margin-top: 0;
            margin-bottom: 0;
            height: 30px;
            font-family: $aller-font;
            font-size: 1.1vw;
        }
        .button {
            &:hover {
                background-color: #FE9B24;
            }
        }
        b, strong {
            color: #FE9B24;
        }
        &-free {

            b, strong {
                color: #788826;
            }

            .button {
                &:hover {
                    background-color: #788826;
                }
            }
        }
    }

    &__item-price {
        font-family: $aller-font;
        font-weight: 600;
        font-size: 4.1vw;
        margin-top: 10px;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 2px solid #8d8d8d;
        height: 6.5vw;

        span {
            font-weight: 700;
            font-size: 2.5vw;
        }

        .available-soon {
            font-weight: 600;
            line-height: .8;
            display: block;
            font-size: 2.3vw;
            padding-top: 1vw;
        }
    }
    @include mq($until: lg) {
        &__list {
            grid-template-columns: repeat(3, 1fr);
        }
        
        &__name {
            font-size: 3.5vw;
        }

        h6 {
            font-size: 2vw;
        }

        &__item {
            padding: 2.5vw;

            &-body {
                li {
                    font-size: 1.3vw;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0.55vw;
                    }
                }
            }
        }

        &__item-price {
            font-size: 5.1vw;
            height: 8.5vw;
    
            span {
                font-size: 3.5vw;
            }
    
            .available-soon {
                font-size: 3.3vw;
                padding-top: 1.5vw;
            }
        }
    }

    @include mq($until: md) {
        &__list {
            grid-template-columns: repeat(2, 1fr);
            gap: 3vw;
        }
        
        &__popular {
            top: -3.8vw;
            padding: 2vw 15%;

            p {
                padding: 0.4vw 0.4vw 0.5vw;
                font-size: 1.7vw;
            }
        }

        &__name {
            font-size: 4vw;
        }

        h6 {
            font-size: 2.5vw;
        }

        &__item {
            padding: 3vw;

            &-body {
                li {
                    font-size: 1.5vw;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0.57vw;
                    }
                }
            }
        }

        &__item-price {
            font-size: 5.5vw;
            height: 9vw;
    
            span {
                font-size: 4vw;
            }
    
            .available-soon {
                font-size: 3.7vw;
                padding-top: 2vw;
            }
        }
    }

    @include mq($until: xs) {
        &__list {
            grid-template-columns: repeat(1, 1fr);
            gap: 6vw;
        }

        &__popular {
            top: -5.2vw;
            p {
                padding: 0.6vw 0.6vw 0.7vw;
                font-size: 3.7vw;
            }
        }

        &__name {
            font-size: 8vw;
        }

        &__item {
            padding: 6vw;
        }

        h6 {
            font-size: 4vw;
        }

        &__item-body li {
            font-size: 3.5vw;

            &::before {
                top: 1.57vw;
            }
        }

        &__item-price {
            font-size: 8vw;
            height: 13vw;

            span {
                font-size: 6vw;
            }
        }

    }
    @include mq($from: 1700px) {
        &__name {
            font-size: 40px;
        }
        &__item {
            padding: 30px;
            h6 {
                font-size: 19px;
            }
        }
        &__item-price {
            font-size: 72px;
            height: 110px;
            span {
                font-size: 44px;
            }
        }
        &__item-body li {
            font-size: 16px;
        }
    }
}

.pay-systems {
    display: flex;
    justify-content: center;

    img {
        height: 30px;
        object-fit: contain;
    }

    li {
        margin: 0 10px;
    }

    @include mq($until: xs) {
        li {
            margin: 0 5px;
        }
    }
}


.subscription-cta {
    margin-bottom: 50px;
    ul {
        display: flex;
        justify-content: center;

        li {
            width: 23%;
            text-align: center;
            margin: 0 2vw;
            padding-left: 0;

            p {
               font-style: italic;
            }
        }
    }

    h3 {
        font-family: $aller-font;
        font-weight: 700;
        font-size: rem(30px);
        margin-bottom: 40px;
        height: 80px;
    }

    @include mq($until: xl) {

        ul {
            li {
                width: 28%;
            }
        }
        h3 {
            font-size: rem(25px);
            height: 60px;
        }
    }

    @include mq($until: lg) {

        ul {
            li {
                width: 28%;           
            }
        }
        h3 {
            font-size: rem(24px);
            height: 50px;
        }
    }

    
    @include mq($until: md) {

        ul {
            flex-direction: column;
            place-items: center;

            li {
                width: 100%;
                max-width: 350px;
                margin: 0 0 30px;
            }

            h3 {
                height: auto;
                margin-bottom: 20px;
            }
        }
    }
}