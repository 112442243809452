.pagination {
  margin: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .page-item {
    padding-left: 7px;
    .page-link {
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    &:last-of-type, &:first-of-type {
      .page-link {
        border: none;
        border-radius: 50%;
        font-size: 26px;
        line-height: 0.6;
        &:hover {
          background-color: white;
        }
      }
    }
    &.disabled {
      opacity: 0;
    }
  }
  &__wrapper {
      min-height: 90px;
  }
  &__item {
    margin: 0 10px;
    padding: 0;
    &:before {
      display: none;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &__link {
    font-weight: $fw-bold;
    font-size: rem(20px);
    color: $color-font-dark;
    &:hover {
      color: $color-main;
      &:after {
        background-color: $color-main;
      }
    }
    &--active {
      color: $color-main;
      position: relative;
      &:after {
        position: absolute;
        content: "";
        height: 2px;
        width: 100%;
        bottom: 1px;
        left: -1px;
        background-color: $color-main;
        transition: 0.3s;
      }
    }
    &--disabled {
      cursor: context-menu !important;
      &:hover {
        color: $color-font-dark;
      }
    }
  }
  &__arrow {
  }
}
.page-link {
  padding: 7px;
  width: 32px;
  height: 32px;
  text-align: center;
  border-radius: 50%;
  color: black;
  line-height: 1;
}
.page-item.active .page-link {
  z-index: 3;
  color: black;
  background-color: #c8c8c8;
  border-color: #c8c8c8;
}