.product {
  height: calc(100vh - 145px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 750px;

  .button__prod_left {
    width: 90px;
  }

  &__buttons {
    display: flex;
    a {
      // padding: 10px 0 8px;
      font-size: 14px;
      text-transform: lowercase;
      border-width: 2px;
      max-width: 150px;
    }
  }

  &__images {
    width: 45%;
    height: fit-content;
    padding: 3vw 6vw;
    height: 100%;
    min-height: 650px;
    a {
      overflow: hidden;
    }
    img {
      width: 100%;
      object-fit: contain;
      object-position: center;
      // transform: scale(1.5);
    }

    * {
      height: 100%;
    }
  }

  &__description {
    padding: 0 3.6vw 4vw 0;
    width: 50%;
    min-width: 530px;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 650px;

    p, span {
      font-size: rem(16px);
      font-style: italic;
    }

    h1 {
      font-size: rem(50px);
      // font-style: italic;
      font-family: $aller-font;
      margin-bottom: 0;
      font-weight: 700!important;
    }

    h2 {
      font-size: rem(18px);
      text-transform: uppercase;
      font-family: $font-family;
    }

    h1, h2 {
      text-align: left;
      font-weight: 600;
    }
    
    &-text {
      min-height: 90px;
      p {
        color: #969696;
      }
    }

  }

  @include mq($until: xl) {
    max-height: 770px;
    h1 {
      font-size: rem(40px);
    }
    &__description {
      max-height: 550px;
    }
  }

  @include mq($until: xl) {
    &__images {
      padding: 3vw 0;
    }
  }

  @include mq($until: lg) {
    &__description {
      padding: 0 3.6vw 0 4vw;
    }
  }

  @include mq($until: md) {
    max-height: unset;
    min-height: unset;
    height: auto;
    flex-direction: column;
    &__description {
      min-width: unset;
    }

    &__images {
      height: 340px;
      min-height: unset;
      width: 100%;
    }

    &__description {
      margin-top: 50px;
      width: 100%;
      max-height: unset;
      padding-right: 0;
      padding-left: 0;
      min-height: unset;
      height: auto;

      & > *, h1 {
        margin-bottom: 25px;
      }

      &-text {
        min-height: 70px;
      }
    }
  }

  @include mq($until: sm) {

  }

  @include mq($until: xs) {

  }
}

.category {
  h1 {
    margin-top: 80px;
    margin-bottom: 50px;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  @include mq($until: sm) {
    margin-bottom: 40px;
  }
}

.product + .products {
  .products__card {
    @include mq($until: xl) {
      &:last-of-type {
        display: none;
      }
    }
    @include mq($until: lg) {
      &:first-of-type {
        display: none;
      }
    }
    @include mq($until: sm) {
      &:first-of-type {
        display: flex;
      }
    }
    @include mq($until: xs) {
      &:last-of-type {
        display: flex;
      }
    }
  }
}
