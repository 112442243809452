.products {

  margin-bottom: $v-mar-xxl;

  &__list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 3.5vw;
  }

  &__buttons {
    display: flex;

    // &:hover {
    //     a {
    //       border: solid 1px #c8c8c8!important;
    //     }
    // }
  }

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &> div:last-of-type {

      transition: all .4s;
    }

    &:hover {
      &> div:last-of-type {
  
      }
    }
  }

  &__image-container {
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center;
      max-height: 270px;
      margin-bottom: 20px;
      // transform: scale(1.5);
      transform-origin: center;
    }

  }

  &__name {
    text-align: center;
    font-size: rem(11px);
    display: block;
    font-weight: 500;
    margin-bottom: 15px;
    line-height: 1.1;

    &:hover {
      color: black;
    }
  }

  h2 {
    font-family: $aller-font;
    font-weight: 600;
    margin-bottom: 100px;
  }

  @include mq($until: xl) {
    &__list {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @include mq($until: lg) {
    &__list {
      grid-template-columns: repeat(3, 1fr);
    }
    &__name {
      font-size: rem(12px);
    }
  }

  @include mq($until: sm) {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }
    &__name {
      font-size: rem(13px);
    }
  }

  @include mq($until: xs) {
    &__name {
      font-size: rem(16px);
    }
    &__list {
      grid-template-columns: repeat(1, 1fr);
      gap: 40px;
    }
  }
}