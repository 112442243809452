.search {

  // border-bottom: 1px solid white;
  position: relative;

  /* webkit solution */
  ::-webkit-input-placeholder { text-align:right; text-transform: uppercase; color: white; }
  /* mozilla solution */
  input:-moz-placeholder { text-align:right; text-transform: uppercase; color: white; }

  &__input {
    background-color: #4c4c4c;
    border-radius: 15px;
    width: fit-content;
    border: none;
    outline: none;
    // font-size: rem(14px);
    padding: 0;
    padding: 2px 15px;
    text-align: right;
    width: 100%;
  }

  &__title {
    font-size: rem(14px);
  }

  // &::after {
  //   content: '';
  //   width: 20px;
  //   height: 20px;
  //   display: block;
  //   position: absolute;
  //   right: 0;
  //   background-image: url(../../images/search.svg);
  //   top: calc(50% - 10px);
  //   opacity: .5;
  //   transition: opacity .5s;
  //   background-size: contain;
  // }
  
  &:active, &:focus, &:hover {
    &::after {
      opacity: 1;
    }
  }

  @include mq($until: xl) {
    &__input {
      width: 17vw;
    }
  }

  @include mq($until: md) {
    &__input {
      width: 85%;
    }
  }

  @include mq($until: sm) {
    width: 100%;
    &__input {
      width: 100%;
      max-width: 400px;
    }
  }
}