.button {
  color: black;
  padding: 13px 20px;
  transition-property: color, border, background-color;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
  background-color: #ffffff3a;
  border-radius: 2px;
  display: inline-block;
  line-height: 1;
  text-align: center;
  border: solid 1px $color-main;
  align-self: flex-start;
  cursor: pointer;

  &:hover {
    background-color: black;
    color: white;
  }

  &__signup {
    background-color: white;
    color: black!important;
    border-radius: 15px;
    border: none;
    width: fit-content;
    font-size: 15px;
    padding: 0 15px;
  }

  &--secondary{
    background-color: transparent;
    color: $color-main;
    border-color: $color-main;

    &:hover {
      color: $color-font-light;
      background-color: $color-main;
      border-color: $color-main;
    }
  }

  &__subscribe {
    background-color: #4c4c4c;
    padding: 6px 13px;
    border-radius: 15px;
    color: #FE9B24!important;
    margin-left: 52px;
    // margin-left: 30px;
  }

  &__pro {
    margin-left: 1.5vw;
    margin-right: 0;
    font-weight: 600;
    padding: 15px 30px;
  }

  &__prod {
    padding: 6px 30px 4px;
    color: black;
    width: 50%;
    font-size: 14px;
    border-color: #cccccc;
    border-radius: 5px;

    &_left {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding: 5px 0;
      color: #FE9B24;
      border: none;
      text-transform: uppercase!important;
      &:hover {
        background-color: transparent;
        color: #e63232;
        border-color: black;
      }
    }

    &_free {
      color: rgb(0, 51, 0);
      &:hover {
        color: black;
      }
    }

    &_right {
      margin-left: -1px;
      width: 80%;
      background-color: #f9f9f9;
      border: none;
      border-radius: 20px;
      &:hover {
        background-color:#e8e8e8;
        color: black;
      }
    }

    svg {
      width: 10px;
      height: 10px;
      margin-left: 3px;
      margin-bottom: 2px;
    }
    svg * {
      transition: fill .3s;
    }
    &:hover {
      svg, svg * {
        // fill: white;
      }
    }
  }

  &__plan {
    background-color: #fafafa;
    border: none;
    font-family: $aller-font;
    font-weight: 700;
    color: black;
    border-radius: 50px;
    width: 100%;
    text-align: left;
    font-size: rem(20px);
    padding: 10px 20px;
    background-image: url(../../images/icons/micrologo.png);
    background-position: 90% center;
    background-repeat: no-repeat;
    transition: all .3s;

    &:hover {
      background-color: #fafafa;
      color: black;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }
  }

  @include mq($until: md) {
    &__subscribe {
      margin-left: 30px;
    }
  }

  @include mq($until: sm) {
    &__subscribe {
      margin-left: 20px;
    }
  }
}