.footer {

  background-color: #1e1e1e;
  padding-top: 30px;
  padding-bottom: 30px;
  
  .container {
    display: grid;
    grid-template-columns: 40% 20% 40%;

    &> div {
      height: 230px;
      flex-direction: column;
      display: flex;
    }
  }

  .logo {
    max-width: 230px;
  }

  &__contact {
    text-align: center;    
    justify-content: flex-end;
  }

  * {
    color: #fafafa;
  }

  &__rights {
    font-size: 12px;
    color: #646464;
  }

  &__email {
    font-family: $secondary-font;
    font-size: rem(17px);
    @include mq($until: xs) {
      margin-top: 40px;
    }
  }

  &__agreement {
    font-size: 13px;
    opacity: 0;
    transition: all .3s;
    position: absolute;
    top: 100%;
    padding-left: 40px;
    
    @include mq($until: md) {
      padding: 0;
    }
  }

  &__newsletter {
    position: relative;
    display: flex;
    flex-direction: column;
    font-family: $secondary-font;
    margin-top: 19px;
    align-items: flex-end;
    width: 90%;

    &> * {
      margin-bottom: 20px;
    }

    input {
      text-align: right;
      width: 90%;
      border: none;
      border-bottom: 2px solid rgb(150, 150, 150);
      background-color: #1e1e1e;
      outline: none;

      &:focus ~ p {
        opacity: 1;
      }
    }
  }

  &__menu {
    
    display: flex;
    margin-bottom: 0;

    &-item {
      margin-right: 1vw;
      font-size: rem(15px);
      text-transform: uppercase;
      color: #fafafa;
      border-bottom:1px solid transparent;
      transition: all .3s;

      &:hover {
        color: #fafafa;
        border-bottom:1px solid gray;
      }
    }
  }

  &__be-pro {
    font-weight: 500;
    br {
      display: none;
    }

    a {
      margin-left: 10px;
      border-bottom: 1px solid white;
    }
  }

  &__signup {
    &> div {
      display: flex;
      justify-content: flex-end;
    }
  }

  &-small{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 15px;
    padding-bottom: 15px;
    &__realization{
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      span{
        transition: color .3s ease-in-out;
      }
      &:hover span{
        color: $color-main;
      }
      img{
        margin-left: 15px;
      }
    }
  }

  @include mq($until: xl) {
    &__menu {
      column-count: 3;
      display: block;
      li {
        margin-top: 0;
      }
    }
  }

  @include mq($until: lg) {
    &__be-pro {
      font-size: rem(14px);
    }
  }

  @include mq($until: md) {
    .container {
      display: grid; 
      grid-template-columns: 1fr; 
      grid-template-rows: 1fr 1fr 1fr; 
      grid-template-areas: 
        "footer__nav"
        "footer__signup"
        "footer__contact"; 

      &>div {
        height: auto;
      }
    }
    padding-top: 40px;
    padding-bottom: 40px;

    .footer__contact { grid-area: footer__contact; }
    .footer__nav { grid-area: footer__nav; }
    .footer__signup { grid-area: footer__signup; }


    &__nav {
      flex-direction: column;
      text-align: center;
      align-items: center;

      .logo {
        margin-bottom: 30px;
      }
    }

    &__newsletter {
      flex-direction: column;
      text-align: center;
      align-items: center;
      width: 100%;

      input {
        text-align: center;
        width: 100%;
        max-width: 400px;
      }
    }

    &>div {
      &:first-child {
        ul, li {
          margin-top: 0;
          a {
            margin: 0;
          }
        }
      }

      &:last-child {
        flex-direction: column-reverse;
      }
    }

    .contact-box__media {
      width: auto;
    }

    &__menu {
      a {
        margin-right: 0;
        margin-left: 15px;
      }
    }

    &__be-pro {
      margin-top: 30px;
    }

    .contact-box__media-sm {
      a {
        margin-left: 6px;
      }

      span {
        display: none;
      }
    }
  }

  @include mq($until: xs) { 

    .container {
      &>div {
        &:first-child {
  
          a {
            margin: 0 5px;
          }
        }
      }
    }

    &__menu {
      margin: 10px auto 0!important;
    }

    &__be-pro {
      text-align: center;
      a {
        margin-left: 0;
      }

      br {
        display: initial;
      }
    }

    &-small{

      * {
        font-size: rem(14px);
      }

      &__copyright {
        position: absolute;
        left: calc(50% - 40px);
      }

      &__realization{
        img{
          width: 25px;
        }
      }
    }
  }
}